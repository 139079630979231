.edu-heading{
    font-size: 30px;
    font-weight: bolder;
}


.edu-sub-heading{
    font-size: 17px;
    font-weight: bolder;
}

.edu-text{
    line-height: 40px;
}